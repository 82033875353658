body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.income-calc.light {
  background-color: #fff;
  color: #000;
}

.income-calc.dark {
  background-color: #000;
  color: #fff;
}

.income-calc {
  width: 100%;
  display: flex;
  -webkit-transition: .4s;
  transition: .4s;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.income-calc_header {
  width: 100%;
  display: flex;
  max-width: 1200px;
  justify-content: space-between;
  align-items:center;
  padding: 20px 0;
}

.background-switch {
  display: flex;
  align-items: center;
  gap: 10px;
}

.income-calc_body {
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  justify-content: space-around;
  flex-wrap: wrap;
}

.income-calc_body_column {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
}

.billInputLabel div {
  display: flex;
  gap: 10px;
}

.billInputLabel {
  display: flex;
  width: 100%;
}

input[type="text"], input[type="number"] {
  width: 50%;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  padding: 10px;
}

.billInputLabel .light input {
  background-color: #fff;
  color: #000;
}

.billInputLabel .dark input {
  background-color: #000;
  color: #fff;
}

.income-calc_monthly_pay {
  display: flex;
  align-items: center;
  gap: 15px;
  -webkit-transition: .4s;
  transition: .4s;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
  padding: 20px;
  width: 100%;
  justify-content: center;
}

.income-calc_monthly_pay input {
  border: 0px;
  position: relative;
}

.income-calc_monthly_pay div {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.income-calc_body_column.result {
 border: 1px solid #f0f0f0;
 border-radius: 5px;
}

.billInputLabel svg:hover {
  cursor: pointer;
}

.income-calc_body_total, .income-calc_body_left {
  font-size: 30px;
}

.income-calc_monthly_pay, .income-calc_monthly_pay .income-calc_salary_input {
  font-size: 30px;
}

.income-calc_monthly_pay input {
  font-size: 30px;
}

.income-calc_monthly_pay.salary, .income-calc_monthly_pay.salary .income-calc_salary_input {
  font-size: 16px;
}

.income-calc_monthly_pay.salary input {
  font-size: 16px;
}

.income-calc_monthly_pay.salary span {
  display: none;
}

.income_calc_add_bill {
  background: none;
  border: 1px solid #f0f0f0;
  padding: 20px 30px;
  border-radius: 5px;
  font-weight: bold;
  -webkit-transition: .4s;
  transition: .4s;
  color: #fff;
}

.income_calc_add_bill:hover {
  background-color: #000;
  color: #fff;
  cursor: pointer;
}

.income_calc_add_bill.dark {
  color: #fff;
}

.income_calc_add_bill.dark:hover {
  background-color: #fff;
  color: #000;
}

.income_calc_add_bill.light {
  color: #fff;
  background-color: #000;
}

.income_calc_add_bill.light:hover {
  color: #000;
  background-color: #fff;
}